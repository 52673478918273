import React from 'react'


export default () => (
  <section className="padding-md mb-40">
    <h2 className="h2">Cash flow</h2>
    <table className="o-table o-table--lrr mb-40">
      <thead>
        <tr>
          <th className="u-bold">Analysis of free working capital</th>
          <th className="u-bold" width="11%">Year to 31 Dec 2023 £’000</th>
          <th q width="11%">Year to 31 Dec 2022 £’000</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Opening balance</td>
          <td className="u-bold">10,254</td>
          <td>16,178</td>
        </tr>
        <tr>
          <td>Distribution of profits (net of tax retentions & QS payments)</td>
          <td className="u-bold">2,530</td>
          <td>2,736</td>
        </tr>
        <tr>
          <td>Transfers from Funds at Lloyd’s</td>
          <td className="u-bold">9,984</td>
          <td>4,772</td>
        </tr>
        <tr>
          <td>Other income</td>
          <td className="u-bold">2,727</td>
          <td>280</td>
        </tr>
        <tr>
          <td>Sale / purchase of capacity</td>
          <td className="u-bold">(500)</td>
          <td>5,051</td>
        </tr>
        <tr>
          <td>Operating costs (inc Hampden / Nomina fees)</td>
          <td className="u-bold">(7,716)</td>
          <td>(4,099)</td>
        </tr>
        <tr>
          <td>Reinsurance costs</td>
          <td className="u-bold">(3,520)</td>
          <td>(3,377)</td>
        </tr>
        <tr>
          <td>Tax</td>
          <td className="u-bold">(236)</td>
          <td>(342)</td>
        </tr>
        <tr>
          <td>Return of capital to shareholders</td>
          <td className="u-bold">(5,181)</td>
          <td>(2,034)</td>
        </tr>
        <tr>
          <td>Transfers to Funds at Lloyd’s</td>
          <td className="u-bold">(4,331)</td>
          <td>(31,578)</td>
        </tr>
        <tr class="has-top-border">
          <td className="u-bold">Free cash flow</td>
          <td>(6,243)</td>
          <td>(16,170)</td>
        </tr>
        <tr>
          <td>Senior debt principal</td>
          <td className="u-bold">59,055</td>
          <td>15,000</td>
        </tr>
        <tr>
          <td>Repayment of borrowings</td>
          <td className="u-bold">(15,000)</td>
          <td>-</td>
        </tr>
        <tr>
          <td>Proceeds from issues of shares</td>
          <td className="u-bold">-</td>
          <td>12,421</td>
        </tr>
        <tr>
          <td>Aquisitions</td>
          <td className="u-bold">(7,153)</td>
          <td>(4,754)</td>
        </tr>
        <tr>
          <td>Net cash flow in the year</td>
          <td className="u-bold">30,659</td>
          <td>(5,924)</td>
        </tr>
        <tr className="is-subtotal">
          <td className="u-bold">Balance carried forward</td>
          <td className="u-bold">40,913</td>
          <td>10,254</td>
        </tr>
      </tbody>
    </table>
    <table className="o-table o-table--lrr">
      <thead>
        <tr>
          <th className="u-bold">Asset value calculation</th>
          <th className="u-bold" width="11%">Year to 31 Dec 2023 £’000</th>
          <th q width="11%">Year to 31 Dec 2022 £’000</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Net assets</td>
          <td className="u-bold">140,101</td>
          <td>117,178</td>
        </tr>
        <tr>
          <td>Add total debt</td>
          <td className="u-bold">59,055</td>
          <td>15,000</td>
        </tr>
        <tr>
          <td>Add deferred tax on intangible asset</td>
          <td className="u-bold">20,136</td>
          <td>14,139</td>
        </tr>
        <tr className="is-subtotal">
          <td>Asset value</td>
          <td className="u-bold">219,293</td>
          <td>146,317</td>
        </tr>
        <tr className="is-subtotal">
          <td>Debt ratio</td>
          <td className="u-bold">27%</td>
          <td>10%</td>
        </tr>
      </tbody>
    </table>
  </section>
)