import React from 'react'

export default () => (
  <section className="padding-md">
    <div className="mb-40">
      <h2 className="h2">Year to 31 December 2023</h2>
    </div>
    <div className="o-table__container">
      <table className="o-table o-table--lrrrrr">
        <thead>
          <tr className="u-bold">
            <th>Year</th>
            <th width="16%">
              Helios retained capacity at 31 Dec 2023 £m
            </th>
            <th width="16%">
              Portfolio mid point forecasts
            </th>
            <th width="16%">
              Helios profits £’000
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>2021</td>
            <td>102.3</td>
            <td>6.8%</td>
            <td className="u-bold">6,831</td>
          </tr>
          <tr>
            <td>2022</td>
            <td>180.9</td>
            <td>8.1%</td>
            <td className="u-bold">18,949</td>
          </tr>
          <tr>
            <td>2023</td>
            <td>244.5</td>
            <td>12%</td>
            <td className="u-bold">5,780</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>31,560</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </section>
)