import React from 'react'
import {Helmet} from 'react-helmet'
import Layout from 'layouts/layout'
import ColumnLayout from 'layouts/column-layout'

// Page sections
import KeyDocuments from './_key-documents'
import Intro from './_intro'
import Yearto31December from './table/_year-to-31-december'
import Yearto31December2023 from './table/_year-to-31-december-2023'
import Yearto31December2022 from './table/_year-to-31-december-2022'
import SummaryBalanceSheet from './table/_summary-balance-sheet'
import CashFlow from './table/_cash-flow'

export default () => (
  <Layout>
    <Helmet>
      <title>Financial summary | Helios Underwriting</title>
    </Helmet>
    <ColumnLayout aside={<KeyDocuments />}>
      <Intro />
      <Yearto31December />
      <Yearto31December2023 />
      <Yearto31December2022 />
      <SummaryBalanceSheet />
      <CashFlow />
    </ColumnLayout>
  </Layout>
)