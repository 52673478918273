import React from 'react'
import Highlights from 'components/highlights'

export default () => (
  <div>
    <section className="padding-md">
      <h1 className="h1 mb-40">Financial summary</h1>
      <div className="u-color-emperor mb-20">
        <p className="lead ">
          The information set out below is a summary of the key items that the Board assesses in estimating the financial position of the Group.
        </p>
        <p>
          Given the Board has no active role in the management of the syndicates within the portfolio, the following approach is taken.
        </p>
      </div>
  </section>

  <Highlights
    title="Board approach"
  >
    <ul className="o-list md:u-col-2">
      <li>
        It relies on the financial information provided by each syndicate.
      </li>
      <li>
        It calculates the amounts due to / from the quota share reinsurers in respect of their share of the profits/losses as well as fees and commissions due.
      </li>
      <li>
        An adjustment is made to exclude pre-acquisition profits on companies bought in the year.
      </li>
      <li>
        Costs relating to stop loss reinsurance and operating costs are deducted.
      </li>
    </ul>
  </Highlights>

  </div>
)