import React from 'react'

export default () => (
  <section className="padding-md">
    <div className="mb-40">
      <h2 className="h2">Year to 31 December 2022</h2>
    </div>
    <div className="o-table__container">
      <table className="o-table o-table--lrrrrr">
        <thead>
          <tr className="u-bold">
            <th>Year</th>
            <th width="16%">
              Helios retained capacity at 31 Dec 2022 £m
            </th>
            <th width="16%">
              Portfolio mid point forecasts
            </th>
            <th width="16%">
              Helios profits £’000
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>2020</td>
            <td>72.0</td>
            <td>3.1%</td>
            <td className="u-bold">2,647</td>
          </tr>
          <tr>
            <td>2021</td>
            <td>99.3</td>
            <td>2.4%</td>
            <td className="u-bold">4,546</td>
          </tr>
          <tr>
            <td>2022</td>
            <td>177.6</td>
            <td>5.8%</td>
            <td className="u-bold">(7,077)</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>116</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </section>
)