import React from 'react'

export default () => (
  <section className="padding-md">
    <h3 className="h2 u-color-night">Summary balance sheet</h3>
    <p>
      (Excluding assets and liabilities held by syndicates)
    </p>
    <div className="o-table__container">
      <table className="o-table o-table--lrr">
        <thead>
          <tr>
            <th></th>
            <th className="u-bold py-10" width="8%">2023 £’000</th>
            <th width="8%" className="py-10">2022 £’000</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Intangible assets</td>
            <td className="u-bold">82,117</td>
            <td>59,375</td>
          </tr>
          <tr>
            <td>Funds at Lloyd’s</td>
            <td className="u-bold">70,754</td>
            <td>73,771</td>
          </tr>
          <tr>
            <td>Other cash</td>
            <td className="u-bold">40,913</td>
            <td>10,254</td>
          </tr>
          <tr>
            <td>Other assets</td>
            <td className="u-bold">4,876</td>
            <td>6,909</td>
          </tr>
          <tr className="is-subtotal">
            <td>Total assets</td>
            <td className="u-bold">198,660</td>
            <td>150,309</td>
          </tr>
          <tr>
            <td>Deferred tax</td>
            <td className="u-bold">22,277</td>
            <td>11,228</td>
          </tr>
          <tr>
            <td>Borrowings</td>
            <td className="u-bold">59,055</td>
            <td>15,000</td>
          </tr>
          <tr>
            <td>Other liabilities</td>
            <td className="u-bold">12,081</td>
            <td>3,839</td>
          </tr>
          <tr className="is-subtotal">
            <td>Total liabilities</td>
            <td className="u-bold">93,413</td>
            <td>30,067</td>
          </tr>
          <tr>
            <td>Total syndicate equity</td>
            <td className="u-bold">34,854</td>
            <td>(5,123)</td>
          </tr>
          <tr class="is-subtotal">
            <td className="u-bold">Total equity</td>
            <td className="u-bold">140,101</td>
            <td>115,119</td>
          </tr>
          <tr class="is-subtotal">
            <td className="u-bold">NTAV – £ per share</td>
            <td className="u-bold">1.89</td>
            <td>1.51</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
)