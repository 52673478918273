import React from 'react'

export default () => (
  <section className="px-medium">
    <div className="o-table__container my-40">
      <h2 className="h2 u-color-night">Year to 31 December</h2>
      <table className="o-table o-table--lrr">
        <thead>
          <tr>
            <th></th>
            <th className="u-bold py-10" width="8%">2023 £’000</th>
            <th width="8%" className="py-10">2022 £’000</th>
          </tr>
        </thead>
        <tbody>
          <tr className="is-subtotal">
            <td className="u-bold">Underwriting profit</td>
            <td className="u-bold">31,560</td>
            <td>116</td>
          </tr>
          <tr>
            <td>Other income:</td>
            <td></td>
            <td></td>
          </tr>
        <tr>
            <td>— fees from reinsurers</td>
            <td className="u-bold">1,408</td>
            <td>562</td>
          </tr>
          <tr>
            <td>— corporate reinsurance recoveries</td>
            <td className="u-bold">-</td>
            <td>33</td>
          </tr>
          <tr>
            <td>Amortisation of goodwill</td>
            <td className="u-bold">619</td>
            <td>1,216</td>
          </tr>
          <tr>
            <td>— investment income</td>
            <td className="u-bold">2,103</td>
            <td>647</td>
          </tr>

          <tr className="is-subtotal">
            <td className="u-bold">Total other income</td>
            <td className="u-bold">4,130</td>
            <td>2,458</td>
          </tr>
          <tr>
            <td>Costs:</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>— Pre-acquisition</td>
            <td className="u-bold">(494)</td>
            <td>(46)</td>
          </tr>
          <tr>
            <td>— Portfolio Stop Loss</td>
            <td className="u-bold">(2,561)</td>
            <td>(1,002)</td>
          </tr>
          <tr>
            <td>— Portfolio Funds at Lloyd’s Financing</td>
            <td className="u-bold">(3,112)</td>
            <td>(1,446)</td>
          </tr>
          <tr>
            <td>— Operating costs</td>
            <td className="u-bold">(6,818)</td>
            <td>(4,033)</td>
          </tr>

          {/* Total costs */}
          <tr className="is-subtotal">
            <td className="u-bold">Total costs</td>
            <td className="u-bold">(12,985)</td>
            <td>(6,527)</td>
          </tr>
          <tr>
            <td>Operating profit/(loss) before impairments of goodwill and capacity</td>
            <td className="u-bold">22,704</td>
            <td>(3,953)</td>
          </tr>
          <tr>
            <td>Tax</td>
            <td className="u-bold">(6,334)</td>
            <td>1,852</td>
          </tr>
          <tr>
            <td>Revaluation of syndicate capacity</td>
            <td className="u-bold">17,987</td>
            <td>2,670</td>
          </tr>
          <tr>
            <td>Income tax relating to the components of other comprehensive income</td>
            <td className="u-bold">(4,497)</td>
            <td>(668)</td>
          </tr>
          <tr class="is-subtotal">
            <td className="u-bold">Comprehensive income / loss</td>
            <td className="u-bold">29,861</td>
            <td>(99)</td>
          </tr>
          <tr class="is-subtotal">
            <td className="u-bold">Earnings per share</td>
            <td className="u-bold">21.56p</td>
            <td>(3.08p)</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
)